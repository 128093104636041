import { BrowserRouter, Route } from 'react-router-dom';

// routes
import { AllRoutes } from './index';
// import axios from 'axios';
// import Login from '../pages/auth/Login';
const Routes = () => {
    return (
        <BrowserRouter>
            {/* {localStorage?.token !== undefined ? <AllRoutes /> : <Route path="/login" element={<Login />} />} */}
            <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
