import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import storage from "redux";
import rootSaga from './sagas';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import persistStorage from "redux-persist/lib/storage/session";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const persistConfig = {
    key: "root",
    storage: persistStorage,
    blacklist: ["user"],
    whitelist: ["auth", "filterList"],
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];
let store: any;
const persistedReducer = persistReducer(persistConfig, reducers);

function configureStore(initialState: {}) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const localstore = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
    sagaMiddleware.run(rootSaga);
    store = localstore;
    return localstore;
}

const _store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));

let persistor = persistStore(_store);

export { persistor, configureStore }
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );


// const store = createStore(
//     persistedReducer,
//     composeWithDevTools(applyMiddleware(thunk))
// );

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
