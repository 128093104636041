import { DASHBOARD_DETAILS, EXTRA_DETAILS, PROFILE_DETAILS } from "./constants";

export const profile = (state = {}, action: any) => {

    switch (action.type) {
        case PROFILE_DETAILS:
            return {
                ...state,
                ...action?.payload
            };
        default:
            return state;
    }
};

export const dashboard = (state = {}, action: any) => {

    switch (action.type) {
        case DASHBOARD_DETAILS:
            return {
                ...state,
                ...action?.payload
            };
        default:
            return state;
    }
};

export const extras = (state = {}, action: any) => {

    switch (action.type) {
        case EXTRA_DETAILS:
            return {
                ...state,
                ...action?.payload
            };
        default:
            return state;
    }
};