// constants
import { AuthActionTypes } from './constants';
import axios from 'axios';
import { keys } from '../../config/keys';
import { toast } from "react-toastify";
import { getProfileDetails } from '../actions';


export type Callbacks = {
    onSuccess?: (val: any) => void;
    onError?: (val: any) => void;
}

export type AuthActionType = {
    type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;
    payload: {} | string;
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: UserData | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser1 = (email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (fullname: string, email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { fullname, email, password },
});

export const forgotPassword = (email: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});

export const request_otp = (client_id: string, callback: Callbacks) => {
    try {

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            client_id,
            otp: '',
        });

        const requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${keys().ServiceIP}/api/auth`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let res = JSON.parse(result)
                if (res?.success === false) {
                    toast.error(res?.error);
                } else {
                    if (callback?.onSuccess) callback.onSuccess(JSON.parse(result))
                }
            })
    }
    catch (ex) {
        if (callback?.onError) callback.onError(ex);
    }
}

export const loginUser = (client_id: string, otp: string, callback: Callbacks) => {
    try {

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            client_id,
            otp,
        });

        const requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${keys().ServiceIP}/api/auth`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let res = JSON.parse(result)

                if (res?.sucess) {
                    localStorage.setItem("token", res?.token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res?.token;
                    axios.defaults.headers.common["token"] = 'Bearer ' + res?.token;
                    if (callback?.onSuccess) {
                        toast.success("Success");
                        callback.onSuccess(res);
                    }
                } else {
                    toast.error(res?.error);
                    if (callback?.onError)
                        callback?.onError(res?.error);
                    console.log("Message", res?.error);
                }
            })
    }
    catch (ex) {
        if (callback?.onError) callback.onError(ex);
    }
}

export const ledgerList = (callback: Callbacks) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append(
            'Authorization',
            `Bearer ${localStorage.token}`
        );

        const raw = JSON.stringify({
            from_date: '01/04/2024',
            to_date: '30/04/2024',
            cocd_list: 'NSE_CASH,BSE_CASH,NSE_FNO',
            show_margin: 'Y',
            show_all_data: 'Y',
        });

        const requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`${keys().ServiceIP}/api/ledger`, requestOptions)
            .then((response) => response.text())
            .then((result) => { console.log('result', result) })
    }
    catch (ex) {
        if (callback?.onError) callback.onError(ex);
    }
}