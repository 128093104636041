const Loader = () => {
    return (
        // <div className="preloader" id="preloader">
        //     <div className="status" id="status">
        //         <div className="spinner">
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //         </div>
        //     </div>
        // </div>
        // <div className="preloader" id="preloader">
        //     <div className="status" id="status">

        //         <div className="loader"></div>

        //     </div>
        // </div>
        // <span className="loader3"></span>
        <div className="preloader" id="preloader">
            <div className="flip-container">
                <div className="flipper">
                    <div className="front">
                        <img src={require('../assets/images/favicon.png')} width={150} />{' '}
                    </div>
                    <div className="back">
                        <img src={require('../assets/images/favicon.png')} width={150} />{' '}
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
