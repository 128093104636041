import axios from "axios";
import { keys } from "../../config/keys";
import { DASHBOARD_DETAILS, EXTRA_DETAILS, PROFILE_DETAILS } from "./constants";
import { toast } from "react-toastify";
import { Callbacks } from "../actions";


export const _dispatchActions = (type: any, data: any) => {
    return {
        type,
        payload: data
    }
}
export const getProfileDetails = () => async (dispatch: any) => {

    // await axios
    //     .post(`${keys().ServiceIP}/api/client-data-view`, {}, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${localStorage.token}`
    //         }
    //     })
    //     .then((res: any) => {
    //         console.log(res);
    //         // dispatch(_dispatchActions(PROFILE_DETAILS, res.data.rows));
    //     })
    //     .catch((e) => {
    //         toast.warning(e.toString());
    //     });

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.token}`);

    const raw = JSON.stringify({});

    // console.log(localStorage.token);

    const requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };
    fetch(`${keys().ServiceIP}/api/client-data-view`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            let rowData = JSON.parse(result);

            const nameParts = rowData.data?.name.split(' ');

            const data = { ...rowData.data, firstNameInitial: nameParts.length > 0 ? nameParts[0][0] : '', lastNameInitial: nameParts.length > 1 ? nameParts[1][0] : '' }
            localStorage.setItem('profileDetails', JSON.stringify(data));
            dispatch(_dispatchActions(PROFILE_DETAILS, data));
        })
        .finally(() => {

        });
};

export const getSettlementDetails = () => async (dispatch: any) => {

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.token}`);

    const raw = JSON.stringify({});

    const requestOptions: any = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    fetch(`${keys().ServiceIP}/api/extras/dashboard`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            let rowData = JSON.parse(result);

            console.log(rowData);
            // const nameParts = rowData.data?.name.split(' ');

            // const data = { ...rowData.data, firstNameInitial: nameParts.length > 0 ? nameParts[0][0] : '', lastNameInitial: nameParts.length > 1 ? nameParts[1][0] : '' }
            localStorage.setItem('extras', JSON.stringify(rowData.data));
            dispatch(_dispatchActions(EXTRA_DETAILS, rowData.data));
        })
        .finally(() => {

        });
};

export const getDashboardList = (callbacks?: Callbacks) => async (dispatch: any) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.token}`);

    const raw = JSON.stringify({});

    const requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    fetch(`${keys().ServiceIP}/api/client-portfolio`, requestOptions)

        .then((response) => response.text())
        .then((result) => {
            let rowData = JSON.parse(result);
            // console.log("RowData", rowData)
            dispatch(_dispatchActions(DASHBOARD_DETAILS, rowData));
            if (callbacks?.onSuccess) callbacks?.onSuccess(rowData);
            // setNetAssetData(rowData?.data?.net_asset_table || []);
            // setBulletinData(rowData?.data?.bulletin || []);
            // setBookedData(rowData?.data?.booked || []);
            // setNet_AssetData(rowData?.data?.net_assets || []);
            // setNotionalData(rowData?.data?.notional || []);
        })
        .finally(() => {

        });
};
