//@ts-ignore
import dayjs from 'dayjs';

// const { ToWords } = require('to-words');
const formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

const formatterWithoutCurrency = new Intl.NumberFormat("en-IN")
const formatterwithoutDecimal = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR", maximumFractionDigits: 0,
})

const formatterWithoutCurrencyWithDecimal = new Intl.NumberFormat("en-IN", {
  minimumFractionDigits: 2, maximumFractionDigits: 2
})

const formatterWithoutCurrencyWithoutDecimal = new Intl.NumberFormat("en-IN", {
  maximumFractionDigits: 0
})

// const toWords = new ToWords({
//   localeCode: 'en-IN',
//   converterOptions: {
//     currency: true,
//     ignoreDecimal: false,
//     ignoreZeroCurrency: false,
//     doNotAddOnly: false,
//     currencyOptions: { // can be used to override defaults for the selected locale
//       name: 'Rupee',
//       plural: 'Rupees',
//       symbol: '₹',
//       fractionalUnit: {
//         name: 'Paisa',
//         plural: 'Paise',
//         symbol: '',
//       },
//     }
//   }
// });

export const keys = {
  // //QA
  // ServicePath: "https://controltapi.imageio.in",
  // ServiceIP: "https://controltapi.imageio.in",
  // authorizedURL: "https://controlt.imageio.in",


  //LOCAL
  ServicePath: "https://botx.acagarwal.com",
  ServiceIP: "https://botx.acagarwal.com",
  authorizedURL: "http://localhost:3000",
  shortDateFormat: "DD-MM-YYYY",
  longDateFormat: "DD-MMM-YYYY",
  dbDateFormat: "YYYY-MM-DD",
  dbTimeFormat: "YYYY-MM-DD HH:mm",
  timeFormat: "HH:mm",
  shortDate: (value: any) => dayjs(value).format("DD-MM-YYYY"),
  displayTime: (value: any) => dayjs(value).format("hh:mm A"),
  // //VKP-SERVER
  // ServicePath: "http://qaapierp.vkpatelgroup.com",
  // ServiceIP: "http://qaapierp.vkpatelgroup.com",
  // authorizedURL: "http://192.168.1.99:3000",

  // //VKP-SERVER-LIVE
  // ServicePath: "http://apierp.vkpatelgroup.com",
  // ServiceIP: "http://apierp.vkpatelgroup.com",
  // authorizedURL: "http://erp.vkpatelgroup.com",

  // alertServerURL: "https://controltapi.imageio.in",
  formatter,
  formatterWithoutCurrency,
  formatterwithoutDecimal,
  formatterWithoutCurrencyWithDecimal,
  formatterWithoutCurrencyWithoutDecimal,
  // toWords,
};
